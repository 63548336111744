@media screen and (max-width: 768px) {
}
.inline.field {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px !important;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover,
.ui.primary.button:active,
.ui.primary.buttons .button:active,
.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
  background-color: #f6bd00 !important;
  color: #000;
}

.ui.inverted.teal.segment {
  background-color: #f6bd00 !important;
  color: #111111;
}
body {
  background-color: #111111;
}

.ui.medium.center.aligned.header,
.ui.primary.buttons .button,
p,
label,
i.icon.check.circle.outline:before,
i.icon.hourglass.start:before,
.ui.basic.center.aligned.segment,
.card-owner,
strong,
.white {
  color: #fff !important;
}

label {
  padding-bottom: 20px;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #f6bd00;
  color: #111111;
}

header {
  text-align: center;
  height: 50px;
  background: #f6bd01;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 30px;
}

.logo-txt {
  font-size: 16px;
  font-weight: bold;
  z-index: 999999;
}

.lang-switcher {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 999999;
}

.ui.teal.large.inverted.raised.segment p {
  color: #000 !important;
}

.player-panel-game {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 100px;
}

.player-panel-game__single {
  margin-left: 15px;
}

.player-container {
  width: 100%;
}

.player-container__single {
  display: flex;
  align-items: center;
  justify-content: center;
}

span.oredering {
  padding-right: 30px;
}

.avatar-all {
  /* padding-right: 30px; */
  width: 50%;
}

a {
  color: #000;
}

.avatar-winner .avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

i.yellow.trophy.huge.icon.two {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.winner-cont {
  text-align: center;
}

.ui.negative.message {
  background: #111111;
  border: 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.games-to-jon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}

.games-to-jon button {
  margin-bottom: 22px !important;
}

.games-to-jon button a {
  color: #000 !important;
}

.delete-this-game {
  margin: 0 auto !important;
  display: flex !important;
}

@media only screen and (max-width: 767px) {
  .ui.selection.dropdown .menu {
    max-height: 9.014286rem;
  }
}

.no-m-r {
  margin-right: 0 !important;
}

button.ui.primary.button.log-out-btn {
  position: absolute;
  top: 7px;
  /* right: 40px; */
  /* top: 17px;
  right: 63px;
  padding: 0; */
  right: 0;
  z-index: 999999;
}

.flex-box-ios {
  display: -webkit-box !important;
}

.field.private-game {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px !important;
  margin-top: -10px !important;
}

.field.private-game label {
  padding-bottom: 0;
  margin-bottom: 0 !important;
  margin-right: 20px !important;
}

a:hover {
  color: #000;
}

.tab-list {
  border-bottom: 0px solid #ccc;
  padding-left: 0;
  color: #fff;
  display: flex;
  justify-content: center;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-weight: 700;
}

.tab-list-active {
  background-color: #f6bd00;
  border-width: 1px 1px 0;
  color: #000;
  margin-bottom: 40px;
  border-radius: 5px;
}

form.pb .ui.input {
  padding-bottom: 20px;
}

/* li.tab-list-item:nth-of-type(1),
li.tab-list-item:nth-of-type(2)  {
  margin-right: 40px;
} */

li.tab-list-item {
  padding: 8px 30px;
}

.flex-box-ios img {
  border: 4px solid #fff;
  border-radius: 20px;
}

@media only screen and (max-width: 991px) {
  .ui.modal > .close {
    top: -25px !important;
    right: -10px !important;
  }
}

.manin-txt {
  color: #f6bd00 !important;
  font-size: 22px;
  font-weight: 700;
}

.game-selection .tab-list-active {
  margin-bottom: 20px;
}

/* @media screen and (max-width: 768px) { */
  .game-selection .tabs {
    margin-top: -20px;
  }
  .game-selection .tab-list {
    border-bottom: 0 solid #ccc;
    padding-left: 0;
    color: #111;
    display: flex;
    justify-content: center;
    top: 0;
    width: 100%;
    margin-top: 0;
    background: #f6bd00;
    min-height: 50px;
    align-items: center;
  }

  .game-selection li.tab-list-item {
    padding: 18px 5px;
    margin-bottom: 0;
  }

  .game-selection .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #828282;
    border-bottom: 3px solid #f6bd00;
    border-radius: 0;
  }

  .game-selection .tab-list-active {
    /* color: #121212 !important; */
    color: #121212;
    border-width: 1px 1px 0;
    color: #000;
    border-bottom: 3px solid #111 !important;
  }

  .game-selection {
    width: 100%;
  }

  .how-to {
    border: 1px solid #f6bd00;
    padding: 20px;
    margin-bottom: 40px;
  }

  #root .info-cont button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 150px !important;
    height: 70px;
    width: 70px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 2px solid #f6bd00 !important;
    background-color: #111 !important;
    color: #f6bd00 !important;
  }

  #root .info-cont.shown button {
    border: 2px solid #fff !important;
    color: #fff !important;
  }

  .share-btn {
    margin: 0 auto !important;
  }
/* } */

@media (min-width: 768px) {
  .share-btn-new {
    display: none !important;
  }
}

.info-cont {
  text-align: center;
}

.user-name-loc {
  position: absolute;
  top: 16px;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  left: 0;
  align-items: center !important;
  width: 100%;
  color: #111111 !important;
}

/*NEW DESIGN TABS*/
/* @media screen and (max-width: 768px) { */
  body {
    background-color: #333;
  }

  ol.tab-list {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }

  .game-selection .tab-list {
    border-bottom: 0 solid #ccc;
    padding-left: 0;
    color: #111;
    display: flex;
    justify-content: center;
    top: 0;
    width: 100%;
    margin-top: 0;
    background: #424242;
    min-height: 50px;
    align-items: center;
  }

  .game-selection .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 3px solid #424242;
    border-radius: 0;
  }

  .game-selection .tab-list-active {
    color: #121212;
    border-width: 1px 1px 0;
    color: #000;
    border-bottom: 3px solid #f6bd00 !important;
  }

  .tab-list-active {
    color: #f6bd00 !important;
    border-width: 1px 1px 0;
    color: #000;
    margin-bottom: 40px;
    border-radius: 5px;
  }

  .tab-list-active {
    background-color: #424242;
    border-width: 1px 1px 0;
    color: #000;
    margin-bottom: 40px;
    border-radius: 5px;
    color: #f6bd00 !important;
  }

  #root .info-cont button {
    /* background-color: transparent !important;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid #f6bd00 !important;
    width: 100px !important;
    height: auto !important;
    bottom: 0;
    right: 0; */
    background: #333333 !important;

  }
  #root .info-cont.shown button {
    /* border: 0 !important; */
    /* border-bottom: 2px solid #fff !important; */
  }

  #root .info-cont.rules-btn button {
    left: 20px !important;
  }
/* } */
/*NEW DESIGN TABS*/


p.how-to.modla-window {
  position: absolute;
  position: fixed;
  z-index: 999999999;
  left: 0;
  top: 50px;
  width: 100%;
  height: 75%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgb(0 0 0 / 94%);
  /* align-items: center;
  justify-content: center; */
}

.yellow {
  color: #f6bd00 !important;
  font-weight: bold !important;
}


.player-panel-game  {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.player-panel-game::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

